import Vue from 'vue'
import App from './App.vue'

import router from '@/router'
import store from '@/store'

Vue.config.productionTip = false

// Initialize Store before starting Vue
store.dispatch('initialize')
.finally(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})
