const defaulter = (function() {

  const self = {

    copy (defaults) {
      return JSON.parse(JSON.stringify(defaults))
    },

    merge (defaults, custom) {
      // start with copy of defaults
      const merged = JSON.parse(JSON.stringify(defaults))
      let key;
      if (typeof custom === 'object' && custom !== null) {
        // for each key in the defaults
        for (key in defaults) {
          // if it's also in the custom
          if (custom.hasOwnProperty(key) && custom[key] !== null) {
            // and they're different
            if (custom[key] !== merged[key]) {
              // use the custom to overwrite the default
              if (typeof custom[key] === 'object') {
                if (typeof merged[key] === 'object' && merged[key] !== null) {
                  merged[key] = self.merge(merged[key], custom[key])
                } else {
                  merged[key] = JSON.parse(JSON.stringify(custom[key]))
                }
              } else {
                merged[key] = custom[key]
              }
            }
          }
        }
        // for each key in the custom
        for (key in custom) {
          // if it's not in the defaults
          if (!defaults.hasOwnProperty(key)) {
            // add the extra custom item to the merged
            if (typeof custom[key] === 'object') {
              merged[key] = JSON.parse(JSON.stringify(custom[key]))
            } else {
              merged[key] = custom[key]
            }
          }
        }
      }
      // console.log('  merged', merged)
      return merged
    }

  }

  return self
})();

export default defaulter
