import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// App Pages
const Signin = () => import('@/pages/Signin.vue')
const Entrance = () => import('@/pages/Entrance.vue')
const Lobby = () => import('@/pages/Lobby.vue')
const Introduction = () => import('@/pages/Introduction.vue')
const Maproom = () => import('@/pages/Maproom.vue')
const Stage1 = () => import('@/pages/Stage1.vue')
const Stage2 = () => import('@/pages/Stage2.vue')
const Stage3 = () => import('@/pages/Stage3.vue')
const Stage4 = () => import('@/pages/Stage4.vue')
const Stage5 = () => import('@/pages/Stage5.vue')
const Stage6 = () => import('@/pages/Stage6.vue')
const Conclusion = () => import('@/pages/Conclusion.vue')
const NotFound = () => import('@/pages/NotFound.vue')
// GM Pages
const GameMaster = () => import('@/pages/GameMaster.vue')


Vue.use(VueRouter)

// Route Navigation Guards
const enteredValidSessionPin = function (to, from, next) {
  if (store.getters.sessionIsOpen) {
    next()
  } else {
    next('/')
  }
}
const isSignedIn = function (to, from, next) {
  const team = store.getters.team
  if (team.id) {
    next()
  } else {
    next('/')
  }
}

// Router Paths
export default new VueRouter({
  mode: 'history',
  // base: 'rooms',
  routes: [
    {
      name: 'Signin',
      path: '/',
      component: Signin,
    },
    {
      name: 'Entrance',
      path: '/entrance',
      component: Entrance,
      beforeEnter: isSignedIn
    },
    {
      name: 'Lobby',
      path: '/lobby',
      component: Lobby,
      beforeEnter: enteredValidSessionPin
    },
    {
      name: 'Introduction',
      path: '/introduction',
      component: Introduction,
      beforeEnter: isSignedIn
    },
    {
      name: 'Maproom',
      path: '/maproom',
      component: Maproom,
      beforeEnter: isSignedIn
    },
    {
      name: 'Stage 1 - Courtyard',
      path: '/stage1',
      component: Stage1,
      beforeEnter: isSignedIn
    },
    {
      name: 'Stage 2 - Lobby',
      path: '/stage2',
      component: Stage2,
      beforeEnter: isSignedIn
    },
    {
      name: 'Stage 3 - Elevators',
      path: '/stage3',
      component: Stage3,
      beforeEnter: isSignedIn
    },
    {
      name: 'Stage 4 - Meeting Room',
      path: '/stage4',
      component: Stage4,
      beforeEnter: isSignedIn
    },
    {
      name: 'Stage 5 - Offices',
      path: '/stage5',
      component: Stage5,
      beforeEnter: isSignedIn
    },
    {
      name: 'Stage 6 - Boardroom',
      path: '/stage6',
      component: Stage6,
      beforeEnter: isSignedIn
    },
    {
      name: 'Conclusion',
      path: '/conclusion',
      component: Conclusion,
      beforeEnter: isSignedIn
    },
    /* Game Master Pages */
    {
      name: 'GameMaster',
      path: '/gm',
      component: GameMaster
    },
    /* Catch All */
    {
      path: '*',
      component: NotFound
    }
  ]
})
