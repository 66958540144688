




import Vue from 'vue';

import 'bootstrap-icons/font/bootstrap-icons.css'

export default Vue.extend({
  name: 'App',
  components: {
  }
});
